<template>
  <div class="main-container">
    <div class="boxes-container">
      <div class="select-box" v-on:click="() => goTo('EventChallengeList')">
        <i size="6x" class="fa fa-star fa-xl"></i>
        <h4>Challenge</h4>
      </div>
      <div class="select-box" v-on:click="() => goTo('EventRandomRumble')">
        <i size="6x" class="fa fa-dice fa-xl"></i>
        <h4>Random Rumble</h4>
      </div>
      <div class="select-box" v-on:click="() => goTo('PassiveEventList')">
        <i size="6x" class="fa fa-coins fa-xl"></i>
        <h4>Passive Events</h4>
      </div>
      <div class="select-box" v-on:click="() => goTo('MiniTournamentConfig')">
        <i size="6x" class="fa fa-medal fa-xl"></i>
        <h4>Mini Tournaments</h4>
      </div>
      <div class="select-box" v-on:click="() => goTo('TreasureHunt')">
        <i size="6x" class="fa fa-gem fa-xl"></i>
        <h4>Treasure Hunt</h4>
      </div>
      <div class="select-box" v-on:click="() => goTo('ThematicLiveOpsMain')">
        <i size="6x" class="fa fa-gem fa-xl"></i>
        <h4>Thematic LiveOps</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { onMounted, onBeforeUnmount } from "vue";
import { useStore } from "vuex";

export default {
  name: "EventRouter",
  setup() {
    const store = useStore();
    const router = useRouter();
    onMounted(() => store.dispatch("loader/loadingStatus", false));
    onBeforeUnmount(() => store.dispatch("loader/loadingStatus", true));
    const goTo = (page) => {
      console.log(page);
      router.push({ name: page });
    };

    return {
      goTo,
    };
  },
};
</script>

<style scoped>
.main-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.boxes-container {
  width: 1000px;
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;
}

.select-box {
  width: 300px;
  height: 300px;
  border-radius: 50px;
  border: 1px solid gray;
  margin: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.select-box:hover {
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer;
}

.select-box.select-box.disabled {
  background-color: lightgray;
}

.select-box.disabled:hover {
  cursor: default;
  background-color: lightgray;
}

</style>